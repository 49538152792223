import { LdoFactory } from "ldo";
import { FoafProfileShapeType } from "./foafProfile.shapeTypes";

/**
 * =============================================================================
 * LDO Factories for foafProfile
 * =============================================================================
 */

/**
 * FoafProfile LdoFactory
 */
export const FoafProfileFactory = new LdoFactory(FoafProfileShapeType);
